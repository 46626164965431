import clsx from 'clsx'
import { htmlReactParse } from 'shared-code/html'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSNCTrustUs } from 'shared-definitions/types'
import Linked from '../service/Linked'
import AppImage from '../ui/AppImage'
import styles from './NCTrustUs.module.css'

interface NCTrustUsProps {
  data: DSNCTrustUs
}

const NCTrustUs: React.FC<NCTrustUsProps> = ({ data }) => (
  <div className={clsx(styles.container, styleUtils.nc, styleUtils.mobileFull)}>
    <div className={styles.inner}>
      <div className={styles.top}>
        {data.logoImage && (
          <AppImage
            imageClassName={styles.image}
            layout="raw"
            image={data.logoImage}
            sizes={150}
            className={styles.logo}
          />
        )}
        <h2 className={styles.title}>{data.title}</h2>
      </div>
      <div className={styles.achievements}>
        {data.achievements.map((achievement, index) => (
          <div key={index} className={styles.achievement}>
            <div className={styles.achievementTitle}>{achievement.title}</div>
            <div className={styles.metric}>{achievement.metric}</div>
          </div>
        ))}
      </div>
      <Linked {...data.pLink} className={styles.desc}>
        <div className={styles.text}>{htmlReactParse(data.description.textHtml)}</div>
        {data.description.image && (
          <AppImage
            layout="raw"
            imageClassName={styles.image}
            className={styles.textImage}
            image={data.description.image}
            sizes={140}
          />
        )}
      </Linked>
    </div>
  </div>
)

export default NCTrustUs
