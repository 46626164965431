import styles from './NCPostsList.module.css'
import PostRowTiny from 'shared-components/posts/PostRowTiny'
import PostRow from 'shared-components/posts/PostRow'
import PostCard from 'shared-components/posts/PostCard'
import { DSNCPostsList, DSPostShortWithAuthor } from 'shared-definitions/types'
import LoadMoreList from 'shared-components/ui/LoadMoreList'
import { getds } from 'ds'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { useCallback, useRef } from 'react'
import { ResponsiveContext } from 'shared-components/contexts/ResponsiveContext'

interface NCPostsListProps {
  data: DSNCPostsList
}

const NCPostsList: React.VFC<NCPostsListProps> = ({ data }) => {
  const { ts } = CommonDataContext.useContainer()
  const { ready, lg } = ResponsiveContext.useContainer()
  const ref = useRef<HTMLDivElement>(null)

  const pageLoader = useCallback(
    async (pageNumber: number, perPage: number) => {
      const dataSource = await getds({ ts })
      const result = await dataSource.getPostsList(data.taxonomy, pageNumber, perPage)
      return result.data.items
    },
    [data.taxonomy, ts]
  )

  return (
    <LoadMoreList
      ref={ref}
      render={useCallback(
        (item: DSPostShortWithAuthor, index) => {
          if (data.size === 'small') {
            return <PostRowTiny key={index} post={item} />
          }

          if (index < 1) {
            return <PostCard post={item} key={index} />
          }
          return <PostRow key={index} post={item} />
        },
        [data.size]
      )}
      className={styles.list}
      buttonClassName={styles.button}
      initial={data.posts}
      loader={pageLoader}
      onLoad={useCallback(() => {
        if (!ready || !lg) {
          return
        }

        const { current } = ref
        if (!current) {
          return
        }

        current.scrollTop = current.scrollHeight
      }, [ready, lg])}
    />
  )
}

export default NCPostsList
