/* eslint-disable react/jsx-no-bind */
import styleUtils from 'shared-components/styles/util.module.css'
import { useStored } from 'shared-components/hooks/use-stored'
import { DSNCPoll, DSPoll, DSSubmitPoll, DSTpl } from 'shared-definitions/types'
import Poll from 'shared-components/ui/Poll'
import styles from './NCPoll.module.css'
import { useSubmit } from 'hooks/use-submit'
import clsx from 'clsx'
import { useState } from 'react'
import BasicButton from 'shared-components/buttons/BasicButton'
import { PostContext } from 'shared-components/contexts/PostContext'
import { AppEventsContext } from 'shared-components/contexts/AppEventsContext'

interface NCPollProps {
  data: DSNCPoll
  tpl: DSTpl
}

interface PollVoterProps {
  poll: DSPoll
  tpl: DSTpl
}

const PollVoter: React.FC<PollVoterProps> = ({ poll, tpl }) => {
  const { appEvent } = AppEventsContext.useContainer()
  const postMeta = PostContext.useContainer()
  const [votedId, setVotedId] = useStored('poll_voted_' + poll.id)
  const { submit, submitting } = useSubmit<DSSubmitPoll>(async ({ dataSource, params }) => {
    const response = await dataSource.sendPoll(params)
    return response.code === 200
  })

  return (
    <Poll
      choicesClassName={clsx({ [styles.choicesDoubleCols]: tpl !== 'sidebar' })}
      className={clsx(styles.poll, { [styles.submitting]: submitting })}
      votedId={votedId}
      poll={poll}
      onSubmit={async choiceId => {
        const success = await submit({ choiceId, pollId: poll.id })
        if (success) {
          setVotedId(choiceId)
          appEvent({
            url: window.location.href,
            event: 'poll-voted',
            postMeta,
            question: poll.question,
            answer: poll.items.find(c => c.id === choiceId)?.answer ?? '',
            responsesCount: poll.votes + 1,
          })
        }
      }}
    />
  )
}

const NCPoll: React.VFC<NCPollProps> = ({ data, tpl }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const page = data.items[pageIndex]
  const hasMore = data.items.length - 1 > pageIndex

  return (
    <div className={styleUtils.nc}>
      {page ? <PollVoter poll={page} tpl={tpl} /> : null}
      {data.items.length > 1 ? (
        <BasicButton
          className={clsx(styles.nextButton, {
            [styles.disabled]: !hasMore,
          })}
          onClick={() => setPageIndex(s => s + 1)}
        >
          {'Next question'}
        </BasicButton>
      ) : null}
    </div>
  )
}

export default NCPoll
