import { publicConfig } from 'config'
import { getds } from 'ds'
import { ComponentProps, useCallback } from 'react'
import RecommendList from 'shared-components/ncui/RecommendList'
import { DSNCRecommend } from 'shared-definitions/types'
import PostCardRelated from 'shared-components/posts/PostCardRelated'

interface NCRecommendProps {
  data: DSNCRecommend
}

type NCRecommendSG = ComponentProps<typeof RecommendList>
const NCRecommend: React.VFC<NCRecommendProps> = ({ data }) => {
  const getRecommend: NCRecommendSG['getRecommend'] = useCallback(async (...args) => {
    const ds = await getds()
    const result = await ds.getRecommend(...args)
    return result.data.recommendations?.items ?? []
  }, [])
  if (!publicConfig('showRecommendedPosts')) {
    return null
  }
  return (
    <RecommendList
      title={data.title}
      getRecommend={getRecommend}
      itemsInList={data.itemsInList}
      postCard={PostCardRelated}
    />
  )
}

export default NCRecommend
